$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
        "wh": $white,
        "g1": $gray-100,
        "g2": $gray-200,
        "g3": $gray-300,
        "g4": $gray-400,
        "g5": $gray-500,
        "g6": $gray-600,
        "g7": $gray-700,
        "g8": $gray-800,
        "g9": $gray-900,
        "bl": $black
) !default;

$bsizes: (0, 1, 2, 3, 4, 5) !default;
$bstyles: ("solid", "dotted", "dashed", "double", "groove", "outset", "ridge") !default;
$bformats: (
                ("b", ("border"), ""),
                ("b-i", ("border"), "!important"),
                ("bv", ("border-top", "border-bottom"), ""),
                ("bv-i", ("border-top", "border-bottom"), "!important"),
                ("bh", ("border-left", "border-right"), ""),
                ("bh-i", ("border-left", "border-right"), "!important"),
                ("bt", ("border-top"), ""),
                ("bt-i", ("border-top"), "!important"),
                ("bb", ("border-bottom"), ""),
                ("bb-i", ("border-bottom"), "!important"),
                ("bl", ("border-left"), ""),
                ("bl-i", ("border-left"), "!important"),
                ("br", ("border-right"), ""),
                ("br-i", ("border-right"), "!important"),
);

@each $name, $rows, $attr in $bformats {
  @each $size in $bsizes {
    @each $style in $bstyles {
      @if $size == 0 {
        @if $style == "solid" {
          .no-#{$name} {
            @each $row in $rows {
              #{$row}: none #{$attr};
            }
          }
        }
      } @else {
        @each $gray, $color in $grays {
          @if $style == "solid" {
            .#{$name}-#{$size}-#{$gray} {
              @each $row in $rows {
                #{$row}: #{$size}px #{$style} #{$color} #{$attr};
              }
            }
          } @else {
            .#{$name}-#{$style}-#{$size}-#{$gray} {
              @each $row in $rows {
                #{$row}: #{$size}px #{$style} #{$color} #{$attr};
              }
            }
          }
        }
      }
    }
  }
}

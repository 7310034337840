$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "poloVariables" as polo;
@use "./frontend/_colors.scss" as colors;

// Importing font Barlow.
//@import url('https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800');

body {
  background-color: polo.$white;
  overflow-x: hidden;
}

div.heading-text {
  margin-bottom: 0;
  padding-bottom: 10px;
}

h1.h2 {
  font-family: polo.$font-family-base;
  color: polo.$primary;
  margin-bottom: 0;
  text-align: center;
  font-size: 3.408em; // 48px
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.014em;
}

h2, h3.h2 {
  color: polo.$black;
  font-size: 3.408em; // 48px
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.017rem;

  &.h3 {
    color: polo.$black;
    font-size: 2.84em; // 40px
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.017rem;
  }

  // Green line below the title.
  &::after {
    content: '';
    display: block;
    margin-top: 24px;
    width: 58px;
    border-bottom: 1px solid polo.$success;
  }

  // The margin changes when the text is center aligned for some reason.
  &.text-center {
    &::after {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

h3.cta {
  color: #5e5e5e;
  font-size: 2.571rem;
  padding-bottom: 15px;
  font-weight: 900;
}

a {
  color: polo.$success;

  &:hover {
    color: polo.$secondary;
  }
}

a, button {
  &.btn.btn-primary:not(.me-2) {
    padding: 12px 36px !important;
    height: unset !important;
  }
}

section {
  p {
    color: #5E5E5E;
    font-size: 1.428rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.007rem;
  }

  ul {
    list-style: url("../images/list-icon.svg");

    li {
      color: #5E5E5E;
      font-size: 1.428rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: 0.007rem;
      padding-left: 10px;

      ol {
        list-style-type: decimal;
      }
    }

    &.times {
      list-style: url("../images/list-times.svg");
    }

    &.people {
      list-style: none;

      li {
        position: relative;

        .fa-li {
          color: polo.$secondary;
        }

        h3 {
          font-size: 1.713rem;
          margin-bottom: 0;
          font-weight: 900;

          .role {
            color: colors.$dcs_accent_green;
          }
        }

        p {
          font-size: 1.428rem;
        }
      }
    }
  }

}

// Adding this helper, which is not in Bootstrap anymore.
.text-justify {
  text-align: justify !important;
}

.background-grey-dcs {
  background: polo.$gray-dcs;
}

// Preventing tabs from having a bigger border below links.
.tabs .nav-tabs {
  .nav-item {
    margin-bottom: -1px;
  }

  .nav-link {
    padding: 16px 32px;
  }
}

// Overriding style for btn-lg.
button.btn.btn-lg, .btn:not(.close):not(.mfp-close).btn-lg, a.btn:not([href]):not([tabindex]).btn-lg {
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  padding: 16px 32px 18px;

  @media (max-width: 991.98px) {
    padding-top: 1px !important;
  }
}

.font-size-1-25 {
  font-size: 1.25rem;
}

div.breadcrumb {
  font-family: polo.$font-family-base;
  color: polo.$primary !important;
  font-size: 1.704rem !important;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.007rem;

  ul {
    li {
      // Overrides a setting from the theme.
      opacity: 1;

      + li::before {
        // Centers the > symbol.
        vertical-align: bottom;
      }

      a {
        font-family: polo.$font-family-base;
        color: polo.$primary !important;
        font-size: 1.704rem !important; // 24px
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.007rem;
      }
    }
  }
}

#page-title {
  font-family: polo.$font-family-base;
  background-color: #D7DEF2;
  padding-top: 0;
  padding-bottom: 0;

  .img-heading {
    padding: 67px 0 54px;
    background-position: 88% -392px !important;
    background-repeat: no-repeat !important;

    @media (max-width: 1800px) {
      background-position: 120% -392px !important;
    }
    @media (max-width: 992px) {
      background-position: right -100px !important;
      background-size: 427px !important;
    }
    @media (max-width: 767px) {
      background-position: center !important;
      background-size: contain !important;
    }
  }
}

.call-to-action {
  h3 {
    font-size: 3.428rem; // 48px
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.012rem;
  }

  p {
    font-size: 1.428rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.007rem;
  }
}

.contact-call-to-action {
  // I couldn't find any other way to do this.
  background-image: url('../images/background_contact_calltoaction.png'), linear-gradient(polo.$secondary, polo.$secondary);
}

.carousel-description-clients {
  > h2 {
    font-size: 2.571rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.013rem;
    margin-bottom: 4px;
  }

  color: #5E5E5E;
  font-size: 1.428rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.007rem;
}

// Icon boxes, used in additional services.
.icon-box.fancy {
  margin-bottom: 90px;

  // Applying the icon styles to img as well.
  .icon {
    top: -40px;

    img {
      width: 160px;
      height: 160px;
      background-color: transparent;
      opacity: 0.09;
      -webkit-transition: all ease 0.5s;
      transition: all ease 0.5s;
    }
  }

  > h3, > p {
    font-style: normal;
    line-height: 141.667%;
    letter-spacing: 0.008rem;
    margin: 0 12px 10px 73px
  }

  > h3 {
    font-size: 1.714rem; //24px
    font-weight: 700;
  }

  > p {
    font-size: 1.428rem; //20px
    font-weight: 500;
  }
}

.offices-count {
  font-size: 12px;
  font-family: polo.$font-family-base;
  color: colors.$dcs_green;
  text-transform: uppercase;
}


.accordion {
  @media (max-width: 429px) {
    padding: 10px;
    margin-top: 20px;
    margin-right: 0;
  }
  @media (max-width: 395px) {
    padding: 0;
    margin-top: 20px;
    margin-right: 0;
  }
}

.accordion-row {
  @media (max-width: 429px) {
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
  }
}

h5.ac-title {
  font-family: polo.$font-family-base;
  padding-left: 10px;
  color: colors.$dcs_darkblue_header;

  &:before {
    color: colors.$dcs_darkblue_header;
  }
}

.fw-30 {
  max-width: 30px !important;
  color: colors.$dcs_green;
}

div.ac-content {
  padding-top: 5px;
  display: none;
  color: colors.$dcs_grey;

  div.office-name {
    @media (max-width: 767px) {
      font-family: polo.$font-family-base;
    }
    font-family: polo.$font-family-base;
    font-size: medium;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .office-address {
    display: flex;
    align-items: baseline;
    gap: 8px;
    padding-left: 10px;

    @media (max-width: 767px) {
      font-family: polo.$font-family-base;
      padding-left: 5px;
      padding-bottom: 0;
    }
    font-family: polo.$font-family-base;
    padding-bottom: 10px;
    font-size: smaller;
  }

  div.network-services {
    @media (max-width: 767px) {
      font-family: polo.$font-family-base;
    }
    font-family: polo.$font-family-base;
    font-size: medium;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  div.service-name {
    @media (max-width: 767px) {
      font-family: polo.$font-family-base;
    }
    font-family: polo.$font-family-base;
    padding-left: 10px;
    font-size: smaller;
  }

  div.button {
    @media (max-width: 767px) {
      font-family: polo.$font-family-base;
    }
    font-family: polo.$font-family-base;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: smaller;

    a.btn {
      background-color: colors.$dcs_lightblue;
      border-color: colors.$dcs_lightblue;
    }
  }
}

.contact-form-container {
  @media (max-width: 976px) {
    position: relative;
    //margin-left: 13px;
    margin-top: 25px;
    //margin-right: 25px;
    margin-bottom: 25px;
    padding: 0;
  }
  position: relative;
  margin-top: 75px;
  padding: 62px 80px;

  .img-form {
    position: absolute;
    top: calc(50% - #{387px / 2});
    left: 0;
    height: 387px;
    width: 100%;
    background-image: url("../images/image_contact_form.png"),
    linear-gradient(polo.$secondary, polo.$secondary);
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 976px) {
      display: none;
    }
  }

  #footer {
    margin-top: 160px !important;
  }


  .card {
    @media (max-width: 1200px) {
      border: 1px;
      margin-top: 10px;
      margin-left: 7px;
      padding-left: 0;
      padding-right: 7px;
      width: 100%
    }
    border: 1px;
    border-radius: 5px;
    max-width: 100%;
    min-width: 198px;
    margin-right: 220px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    .card-body {
      @media (max-width: 976px) {
        padding-left: 0;
        padding-right: 0;
      }
      padding-top: 22px;
      padding-left: 1px;
      padding-right: 1px;
      padding-bottom: 0;
    }

    .card-header {
      padding: 12px;
      padding-left: 21px;
      padding-bottom: 20px;;
      padding-top: 20px;
      font-size: 18px;
      text-align: left;
      @media (max-width: 768px) {
        font-size: 18px;

      }

      .title {
        line-height: 1em;
        margin-top: 8px;
      }

      .subtitle {
        padding-bottom: 5px;
        padding-top: 8px;
      }
    }
  }
}

#header {
  .header-inner {
    #logo {
      top: 26.5px;

      // Disabling transitions, because that's more pleasing
      // to see when switching from sticky to non-sticky.
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;

      a > img {
        height: 104px;
      }
    }

    // Disabling transitions, because that's more pleasing
    // to see when switching from sticky to non-sticky.
    .menu-left ul li {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }

    #mainMenu nav > ul > li {

      &.dropdown {
        margin-right: 13px;

        &:before {
          color: polo.$black;
          content: "\e930";
          transform: rotate(90deg);
          opacity: 0.6;
          position: absolute;
          right: -5px;
          top: 2px;
          font-size: 1.136em;
          display: inline;
        }

        .dropdown-arrow {
          position: absolute;
          right: 20px;
        }

        .dropdown-menu {
          margin: -46px 0 0;
          box-shadow: 0 0 5px 0 #00000022;

          li {
            a {
              font-size: 0.852rem;
              text-transform: uppercase;
              line-height: 1.4;
            }
          }
        }
      }


      @media (max-width: 991px) {
        &.dropdown {
          min-height: 41px;

          &:before {
            right: 30px;
            top: unset;
          }

          .dropdown-arrow {
            position: absolute;
            right: 20px;
          }

          a {
            width: 75%;
          }

          .dropdown-menu {
            margin: 0 0;
            box-shadow: unset;

            li {
              a {
                font-size: 0.994rem;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1200px) {
      .container {
        max-width: unset !important;

        .menu-left ul li {
          margin-left: 0;

          a {
            padding-left: 7px;
            padding-right: 7px;

            &.btn-menu {
              color: polo.$white;
              width: 150px;
              height: 38px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }

  // Style for the sticky header.
  &.header-sticky.sticky-active {
    height: polo.$header-sticky-height;
    line-height: polo.$header-sticky-height;

    .header-inner {
      height: polo.$header-sticky-height;

      #logo {
        height: polo.$header-sticky-height;
        top: 8px;
      }

      .header-extras {
        height: polo.$header-sticky-height;
      }
    }

    #mainMenu-trigger {
      height: polo.$header-sticky-height;
    }
  }

  // Incrementing the space next to the menu.
  #mainMenu.menu-left nav {
    margin-left: 20px;

    @media (min-width: 1200px) {
      margin-left: 36px;
    }
  }

  @media (max-width: 991px) {
    height: polo.$header-responsive-height;
    line-height: polo.$header-responsive-height;

    .header-inner, #header-wrap {
      height: polo.$header-responsive-height;
    }
    .header-inner {
      height: polo.$header-responsive-height;

      #logo {
        height: polo.$header-responsive-height;
        top: 12px;
      }

      .header-extras {
        height: polo.$header-responsive-height;
      }
    }

    #mainMenu {
      background: #ffffff;
      max-width: 300px;
      width: 100%;
      margin-left: -30px;
    }

    #mainMenu-trigger {
      height: polo.$header-responsive-height;
    }

    &.header-sticky.sticky-active {
      height: polo.$header-responsive-height;
      line-height: polo.$header-responsive-height;

      .header-inner {
        height: polo.$header-responsive-height;

        #logo {
          height: polo.$header-responsive-height;
          top: 12px;
        }

        .header-extras {
          height: polo.$header-responsive-height;
        }
      }

      #mainMenu-trigger {
        height: polo.$header-responsive-height;
      }
    }
  }
}

// Vertical aligning for buttons on the right.
.header-extras > ul > li .btn {
  // All buttons.
  &:not(.close):not(.mfp-close) {
    margin-bottom: 0;
  }
}

#footer {
  color: white;

  h4 {
    color: white;
  }

  .widget {
    div.widget-title {
      color: white;
    }

    ul.list {
      li {
        a:not(.btn) {
          color: #FFFFFF;

          &:hover {
            color: colors.$dcs_lightgrey !important;
          }
        }
      }
    }
  }

  .crafted {
    color: white;

    a {
      color: white;
      font-weight: bold;

      &:hover {
        color: colors.$dcs_lightgrey !important;
      }
    }
  }

  .company-title {
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .subtitle {
    color: white;
    font-size: 14px;
    font-weight: 400;
  }

  .col-footer {
    padding-left: 30px;
    padding-right: 15px;

    &:last-child {
      border-right: none;
    }

    @media (max-width: 991px) {
      padding-left: 15px;
      border-right: none;
    }

    .widget {
      .widget-title {
        font-size: 1.2784em; // 18px.
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.006em; // 0.09px.
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      .list {
        > li {
          font-size: 1em; // 14px.
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005em; // 0.07px.
          margin-bottom: 16px;
          opacity: 0.7;
        }
      }
    }
  }

  .copyright-content {
    background-color: #00254D;
    padding: 24px 0;

    .copyright-text {
      opacity: 0.7;
    }
  }

  @media (max-width: 767px) {
    text-align: center;
  }
}

.oi-i18n-locale-switch {
  .dropdown-toggle:after {
    display: none !important;
  }

  #oi_i18n_locale_switch {
    // Overrides an inline style.
    margin-top: 0 !important;
    padding: polo.$btn-padding-x polo.$btn-padding-y !important;
    line-height: 1 !important;

    // Prevents the height from decreasing in responsive.
    height: auto;
    color: colors.$dcs_black;
    padding-left: 10px !important;

    img {
      margin-right: 5px;
    }

    &:hover, &:active, &:focus {
      color: white !important;
    }

    @media (max-width: 1024px) {
      color: colors.$dcs_darkblue_header !important;

      &:hover, &:active, &:focus {
        span {
          color: white !important;
        }
      }
    }
  }

  .dropdown-menu {
    > a {
      line-height: initial;
    }
  }

  @media (max-width: 1024px) {
    height: polo.$header-responsive-height !important;

    #oi_i18n_locale_switch {
      margin-top: 0 !important;
    }
  }
}

.certification-wrapper {
  font-family: Barlow, sans-serif;
  color: #9EA4B5;
  font-size: 1.143rem;

  h2 {
    font-size: 2.571rem;
    color: #5E5E5E;
    padding-bottom: 15px;

    &:after {
      display: none;
    }
  }
}

.client-logos {
  img {
    filter: grayscale(100);
  }
}

@media (max-width: 991px) {
  .client-logos .polo-carousel-item {
    padding: 10px !important;
  }
}

.top-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 15px 0;
}

.dcs-box {
  padding: 50px;
  height: 100vw;
  max-height: 400px;
  position: relative;


  @media (min-width: 992px) {
    height: 33.333vw;
  }

  @media (max-width: 360px) {
    height: auto;
    max-height: 600px;
  }

  &.text-light {
    > * {
      color: polo.$white;
    }
  }

  h3 {
    font-size: 3em;
    font-weight: 900;
    @media (min-width: 992px) {
      font-size: 4vw;
    }
    @media (min-width: 1440px) {
      font-size: 5em;
    }
  }

  p {
    font-size: 1.435em;
    line-height: 1.7;
    @media (min-width: 992px) and (max-width: 1024px) {
      font-size: 1.265em;
    }
  }

  &.icon {
    background-image: var(--icon);
    background-repeat: no-repeat;
    background-position: top left;
    padding: 75px 25px 25px 75px;

    h3 {
      font-size: 1.713em;
    }
    @media (max-width: 991px) {
      padding: 75px 20px 25px 20px;
    }
  }
}

.document-download {
  margin-bottom: 20px;

  a {
    font-size: 1.712em;
    width: 100%;

    img {
      height: 30px;
      width: 30px;
      transition: all 0.25s;
    }
  }

  &:hover {
    img {
      transform: scale(1.25);
    }
  }
}

.governance-documents {
  margin-top: 35px;
  max-width: 400px;
}

.oi-expandable-wrapper {
  &.vertical-market {
    @media (max-width: 767px) {
      .oi-expandable-wrapper-row {
        .oi-expandable-item.no-icons {
          min-height: 425px;
          .oi-expandable-content {
            overflow: visible;
            opacity: 1;

            p {
              display: block;
            }
          }
        }
      }
    }
    @media (max-width: 585px) {
      .oi-expandable-wrapper-row {
        .oi-expandable-item.no-icons {
          min-height: 580px;
        }
      }
    }
    @media (max-width: 375px) {
      .oi-expandable-wrapper-row {
        .oi-expandable-item.no-icons {
          min-height: 700px;
        }
      }
    }
  }
}

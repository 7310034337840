$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
$squares: (16, 20, 25, 32, 40, 50, 64, 75, 80, 100, 128, 150, 160, 200, 256) !default;

@each $square in $squares {
  .sq-#{$square} {
    width: #{$square}px;
    height: #{$square}px;
    min-width: #{$square}px;
    min-height: #{$square}px;
    max-width: #{$square}px;
    max-height: #{$square}px;
  }
}

@each $square in $squares {
  .sq-#{$square}-i {
    width: #{$square}px !important;
    height: #{$square}px !important;
    min-width: #{$square}px !important;
    min-height: #{$square}px !important;
    max-width: #{$square}px !important;
    max-height: #{$square}px !important;
  }
}

@each $square in $squares {
  .r-#{$square} {
    width: #{$square}px;
    height: #{$square}px;
    min-width: #{$square}px;
    min-height: #{$square}px;
    max-width: #{$square}px;
    max-height: #{$square}px;
    border-radius: 50%;
  }
}

@each $square in $squares {
  .r-#{$square}-i {
    width: #{$square}px !important;
    height: #{$square}px !important;
    min-width: #{$square}px !important;
    min-height: #{$square}px !important;
    max-width: #{$square}px !important;
    max-height: #{$square}px !important;
    border-radius: 50%;
  }
}